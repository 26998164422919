<app-step-wrapper [title]="dataService.steps[3]">
  <form [formGroup]="dataService.enrollmentForm" (click)="dataService.printForm()">
    <p-accordion [activeIndex]="0">
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div>Ora di religione</div>
            <p-badge [severity]="dataService.enrollmentForm.get('religione')?.value === null ? 'warning' : 'info'"
              value="{{dataService.enrollmentForm.get('religione')?.value === null ? 'Da completare':'Completato'}}" />
          </span>
        </ng-template>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <app-label>Seleziona un'opzione:</app-label>
            <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'religione'"
              [options]="[{'value': true, 'label':'Sì'},{'value': false, 'label':'No'}]">
            </app-radio-button>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div>DeA Sport</div>
            <p-badge [severity]="dataService.enrollmentForm.get('dea_sport')?.value !== true ? 'warning' : 'info'"
              value="{{dataService.enrollmentForm.get('dea_sport')?.value !== true ? 'Da completare' : 'Completato'}}" />
          </span>
        </ng-template>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <div class="tw-flex tw-flex-row tw-gap-2 tw-flex-nowrap">
              <p-checkbox class="md:tw-order-2" formControlName="dea_sport" [binary]="true"
                inputId="dea-sport-checkbox"></p-checkbox>
              <label class="tw-ms-1 md:tw-order-1" for="dea-sport-checkbox">Ho preso visione e accetto</label>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div>Regolamento</div>
            <p-badge [severity]="dataService.enrollmentForm.get('regolamento')?.value !== true ? 'warning' : 'info'"
              value="{{dataService.enrollmentForm.get('regolamento')?.value !== true ? 'Da completare' : 'Completato'}}" />
          </span>
        </ng-template>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <div class="tw-flex tw-flex-row tw-gap-2 tw-flex-nowrap">
              <p-checkbox class="md:tw-order-2" formControlName="regolamento" [binary]="true"
                inputId="regolamento-checkbox"></p-checkbox>
              <label class="tw-ms-1 md:tw-order-1" for="regolamento-checkbox">Ho preso visione e accetto</label>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div>Privacy Policy</div>
            <p-badge
              [severity]="dataService.enrollmentForm.get('privacy_policy_1')?.value === null || dataService.enrollmentForm.get('privacy_policy_2')?.value === null || dataService.enrollmentForm.get('privacy_policy_3')?.value === null ? 'warning' : 'info'"
              value="{{dataService.enrollmentForm.get('privacy_policy_1')?.value === null || dataService.enrollmentForm.get('privacy_policy_2')?.value === null || dataService.enrollmentForm.get('privacy_policy_3')?.value === null ? 'Da completare':'Completato'}}" />
          </span>
        </ng-template>
        <div class="tw-font-semibold">Sezione A:</div>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <app-label>Seleziona un'opzione:</app-label>
            <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'privacy_policy_1'"
              [options]="[{'value': true, 'label':'Acconsento'},{'value': false, 'label':'Non acconsento'}]">
            </app-radio-button>
          </div>
        </div>
        <div class="tw-my-8">
          <hr>
        </div>
        <div class="tw-font-semibold">Sezione B:</div>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.<br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <app-label>Seleziona un'opzione:</app-label>
            <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'privacy_policy_2'"
              [options]="[{'value': true, 'label':'Acconsento'},{'value': false, 'label':'Non acconsento'}]">
            </app-radio-button>
          </div>
        </div>
        <div class="tw-my-8">
          <hr>
        </div>
        <div class="tw-font-semibold">Sezione C:</div>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <app-label>Seleziona un'opzione:</app-label>
            <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'privacy_policy_3'"
              [options]="[{'value': true, 'label':'Acconsento'},{'value': false, 'label':'Non acconsento'}]">
            </app-radio-button>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </form>
</app-step-wrapper>
