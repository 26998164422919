import { User } from './user/user.model';

export class Enrollment {
  id?: string;
  // BASE DATA
  uuid?: string;
  archiviata?: boolean;
  sede?: string;
  anno_scolastico?: string;
  dipartimento?: string;
  anno?: number;
  sezione?: string;
  religione?: boolean;
  privacy?: boolean;
  // USERS
  users?: User[];
  createdUsers?: boolean;

  constructor(json?: any) {
    if (!!!json) return;
    this.id = json['_id'] || json['id'];
    // BASE DATA
    this.uuid = json['uuid'];
    this.sede = json['sede'];
    this.anno_scolastico = json['anno_scolastico'];
    this.dipartimento = json['dipartimento'];
    this.anno = json['anno'];
    this.sezione = json['sezione'];
    this.religione =
      (json['religione'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.privacy =
      (json['privacy'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    // USERS
    this.users = (json['users'] || []).map((user: any) => new User(user));
    this.createdUsers =
      (json['createdUsers'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
  }
}
