<app-step-wrapper [title]="dataService.steps[3]">
  <form [formGroup]="dataService.enrollmentForm" (click)="dataService.printForm()">
    <p-accordion [activeIndex]="0">
      <!-- RELIGIONE -->
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div>Ora di religione</div>
            <p-badge [severity]="dataService.enrollmentForm.get('religione')?.value === null ? 'warning' : 'info'"
              value="{{dataService.enrollmentForm.get('religione')?.value === null ? 'Da completare':'Completato'}}" />
          </span>
        </ng-template>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <strong>
            La scelta deve essere effettuata obbligatoriamente all’atto dell’iscrizione e non può essere cambiata nel
            corso dell’anno scolastico cui si riferisce.
          </strong>
          <p>
            Premesso che lo Stato assicura l’insegnamento della religione cattolica di ogni ordine e grado in conformità
            all’accordo che apporta modifiche al Concordato Lateranense (art. 9.2) la presente costituisce richiesta
            dell’autorità scolastica in ordine all’esercizio di scegliere se avvalersi o non avvalersi della religione
            cattolica.
          </p>
          <div class="tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <app-label>Seleziona un'opzione:</app-label>
            <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'religione'"
              [options]="[{'value': true, 'label':'Sì'},{'value': false, 'label':'No'}]">
            </app-radio-button>
          </div>
        </div>
      </p-accordionTab>
      <!-- RELIGIONE -->
      <!-- ASD DEA -->
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div>ASD IDEA</div>
            <p-badge [severity]="dataService.enrollmentForm.get('dea_sport')?.value !== true ? 'warning' : 'info'"
              value="{{dataService.enrollmentForm.get('dea_sport')?.value !== true ? 'Da completare' : 'Completato'}}" />
          </span>
        </ng-template>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <strong>Domanda di ammissione in qualità di associato alla Associazione Sportiva Dilettantistica IDEA</strong>
          <div>
            Dichiaro di aver preso visione dello statuto e di accettarlo e rispettarlo in ogni suo punto;
          </div>
          <div>
            Dichiaro di impegnarmi al pagamento della quota associativa annuale di € 50,00 da versare contestualmente
            alla quota di iscrizione.
          </div>
          <div class="tw-flex tw-flex-col tw-items-start">
            <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
              (click)="showPdfDialog('statuto')">
              <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Visualizza statuto</span>
            </button>
          </div>
          <div class="tw-mt-4 tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <div class="tw-flex tw-flex-row tw-gap-2 tw-flex-nowrap">
              <p-checkbox class="md:tw-order-2" formControlName="dea_sport" [binary]="true"
                inputId="dea-sport-checkbox"></p-checkbox>
              <label class="tw-ms-1 md:tw-order-1" for="dea-sport-checkbox">Richiedo ammissione</label>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <!-- ASD DEA -->
      <!-- REGOLAMENTO -->
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div>Regolamento</div>
            <p-badge [severity]="dataService.enrollmentForm.get('regolamento')?.value !== true ? 'warning' : 'info'"
              value="{{dataService.enrollmentForm.get('regolamento')?.value !== true ? 'Da completare' : 'Completato'}}" />
          </span>
        </ng-template>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <p>
            Si prega di prendere visione del regolamento scolastico:
          </p>
          <div class="tw-flex tw-flex-col tw-items-start">
            <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
              (click)="showPdfDialog('regolamento')">
              <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Visualizza regolamento</span>
            </button>
          </div>
          <div class="tw-flex tw-flex-col tw-items-start md:tw-items-end">
            <div class="tw-flex tw-flex-row tw-gap-2 tw-flex-nowrap">
              <p-checkbox class="md:tw-order-2" formControlName="regolamento" [binary]="true"
                inputId="regolamento-checkbox"></p-checkbox>
              <label class="tw-ms-1 md:tw-order-1" for="regolamento-checkbox">Ho preso visione e accetto</label>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </form>
  <p-dialog [modal]="true" [draggable]="false" [dismissableMask]="true" [header]="dialogTitle" [(visible)]="visible"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '1000px' }">
    <div class="tw-rounded tw-overflow-clip tw-h-[480px] tw-bg-neutral-200 tw-m-0 tw-p-0">
      <pdf-viewer class="tw-h-full tw-w-full" [src]="src" [render-text]="false" [original-size]="false"
        [show-borders]="true" [autoresize]="true">
      </pdf-viewer>
    </div>
  </p-dialog>
</app-step-wrapper>
