import { RadioButtonInterface } from 'src/app/models/radio.interface';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  template: `<div
    [formGroup]="xFormGroup"
    class="tw-flex tw-flex-wrap tw-justify-start tw-gap-x-8 tw-gap-y-4"
  >
    <div
      *ngFor="let option of options; let index = index"
      class="tw-flex tw-items-center"
    >
      <div>
        <p-radioButton
          [value]="option.value"
          formControlName="{{ xFormControlName }}"
          inputId="{{ xFormControlName + '-' + index }}"
        >
        </p-radioButton>
        <div *ngIf="option.description" class="tw-text-xs tw-opacity-0"> </div>
      </div>
      <div class="tw-ml-2">
        <label for="{{ xFormControlName + '-' + index }}">
          {{ option.label }}
        </label>
        <div class="tw-text-xs tw-opacity-50">
          {{ option.description }}
        </div>
      </div>
    </div>
  </div>`,
})
export class RadioButtonComponent {
  @Input() xFormGroup!: any;
  @Input() xFormControlName!: string;
  @Input() options: RadioButtonInterface[] = [];
}
