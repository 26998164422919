import { PhotoCaptureDialogComponent } from '../photo-capture-dialog/photo-capture-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-img-upload',
  template: `<ng-container>
    <div
      *ngIf="inputBase64 == undefined"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-border-2 tw-border-dashed tw-rounded tw-aspect-video"
    >
      <ng-content></ng-content>
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-2">
        <button
          pButton
          type="button"
          (click)="openPhotoCaptureDialog()"
          class="p-button-primary p-button-outlined tw-flex tw-justify-center"
        >
          <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">
            Scatta Foto
          </span>
        </button>
        <button
          pButton
          type="button"
          class="p-button-primary p-button-outlined tw-flex tw-justify-center"
        >
          <span
            style="color: inherit;"
            class="tw-py-2 tw-px-4 tw-text-sm"
            (click)="triggerFileUpload()"
          >
            Carica dalla Galleria
          </span>
          <input
            type="file"
            id="{{ uuid }}"
            accept="image/*"
            class="tw-hidden"
            (change)="onFileChange($event)"
          />
        </button>
      </div>
    </div>
    <div
      *ngIf="inputBase64 != undefined"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-border-2 tw-border-dashed tw-rounded tw-aspect-video tw-relative"
    >
      <img
        alt="{{ uuid }}"
        src="{{ inputBase64 }}"
        class="tw-w-full tw-h-full tw-rounded document-img"
      />
      <div class="tw-absolute tw-right-0 tw-top-0 tw-z-30 tw-p-2">
        <button
          pButton
          type="button"
          class="p-button-danger tw-py-2 tw-px-4 tw-text-sm"
          (click)="deleteImg.emit()"
        >
          <span class="tw-py-1 tw-px-2 tw-text-sm" style="color: inherit;">
            Elimina
          </span>
        </button>
      </div>
    </div>
  </ng-container>`,
  styles: [
    `
      .document-img {
        background-color: rgb(229, 231, 235);
        object-position: center;
        object-fit: contain;
      }
    `,
  ],
})
export class ImgUploadComponent {
  @Input() inputBase64?: string | undefined;
  @Output() deleteImg: EventEmitter<any> = new EventEmitter();
  @Output() outputBbase64: EventEmitter<string> = new EventEmitter();

  public readonly uuid: string = uuidv4();
  public id: string = `img-upload-component-${this.uuid}`;
  private _ref: DynamicDialogRef | undefined;

  constructor(private _dialogService: DialogService) {}

  private setAndEmitBase64Img(base64: string): void {
    this.inputBase64 = base64;
    this.outputBbase64.emit(this.inputBase64);
  }

  public openPhotoCaptureDialog(): void {
    this._ref = this._dialogService.open(PhotoCaptureDialogComponent, {
      width: '95vw',
      maximizable: false,
      header: 'Fotocamera',
      style: { 'max-width': '500px' },
      baseZIndex: 10000,
      dismissableMask: true,
      closeOnEscape: true,
      closable: false,
      modal: true,
    });

    this._ref.onClose.subscribe((data: any) => {
      if (data && typeof data == 'string') this.setAndEmitBase64Img(data);
    });
  }

  triggerFileUpload() {
    (document.getElementById(this.uuid) as HTMLButtonElement)?.click();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      if (!!!file) return;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (typeof e?.target?.result == 'string') {
          this.setAndEmitBase64Img(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  }
}
