<app-step-wrapper [title]="dataService.steps[0]">
  <form [formGroup]="dataService.enrollmentForm" (click)="dataService.printForm()">
    <app-form-grid>
      <div>
        <app-label>Seleziona la sede:</app-label>
        <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'sede'"
          [options]="dataService.headquartersListing">
        </app-radio-button>
      </div>
      <div>
        <app-label>Seleziona l'anno scolastico:</app-label>
        <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'anno_scolastico'"
          [options]="scholasticYearsListing">
        </app-radio-button>
      </div>
      <div>
        <app-label>Seleziona l'indirizzo di studi:</app-label>
        <p-dropdown [classList]="'tw-w-full'" formControlName="dipartimento"
          [options]="dataService.dynamicDepartmentsListing" placeholder="Seleziona l'indirizzo di studi">
        </p-dropdown>
      </div>
      <div>
        <app-label>Seleziona l'anno accademico:</app-label>
        <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'anno'"
          [options]="dataService.dynamicAcademicYearsListing">
        </app-radio-button>
      </div>
      <div>
        <app-label>Prima lingua:</app-label>
        <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'prima_lingua'"
          [options]="[{label:'Inglese', value:'Inglese'}]">
        </app-radio-button>
      </div>
      <div *ngIf="dataService.enrollmentForm.get('dipartimento')?.value === 'Secondaria di I Grado'">
        <app-label>Seconda lingua:</app-label>
        <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'seconda_lingua'"
          [options]="[{label:'Spagnolo', value:'Spagnolo'}]">
        </app-radio-button>
      </div>
      <div *ngIf="dataService.enrollmentForm.get('dipartimento')?.value === 'Liceo Linguistico Internazionale'">
        <app-label>Seconda lingua:</app-label>
        <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'seconda_lingua'"
          [options]="[{label:'Francese', value:'Francese'},{label:'Tedesco', value:'Tedesco'}]">
        </app-radio-button>
      </div>
      <div *ngIf="dataService.enrollmentForm.get('dipartimento')?.value === 'Liceo Linguistico Internazionale'">
        <app-label>Terza lingua:</app-label>
        <app-radio-button [xFormGroup]="dataService.enrollmentForm" [xFormControlName]="'terza_lingua'"
          [options]="[{label:'Spagnolo', value:'Spagnolo'}]">
        </app-radio-button>
      </div>
    </app-form-grid>
    <div class="tw-mt-16 tw-rounded tw-border tw-p-4" formGroupName="ultimo_anno">
      <app-form-grid>
        <div class="tw-col-span-1 md:tw-col-span-2">
          Informazioni relative all'anno scolastico e Istituto nei quali lo studente ha frequentato l'ultima classe o
          sostenuto esami:
        </div>
        <div>
          <app-label>Nome Istituto:</app-label>
          <input pInputText toUppercase formControlName="istituto" type="text" class="tw-w-full" />
        </div>
        <div>
          <app-label>Anno scolastico:</app-label>
          <input pInputText toUppercase formControlName="anno_scolastico" type="text" class="tw-w-full" />
        </div>
        <div class="tw-col-span-1 md:tw-col-span-2">
          <app-label>Seleziona un'opzione:</app-label>
          <app-radio-button [xFormGroup]="dataService.enrollmentForm.get('ultimo_anno')" [xFormControlName]="'tipo'"
            [options]="lastYearOptions">
          </app-radio-button>
        </div>
        <div>
          <app-label>{{detailsLabel}}:</app-label>
          <input pInputText toUppercase placeholder="{{detailsPlaceholder}}" formControlName="dettaglio" type="text"
            class="tw-w-full" />
        </div>
        <div>
          <app-label>Esito:</app-label>
          <p-dropdown [classList]="'tw-w-full'" formControlName="esito" [options]="resultsListing"
            placeholder="Seleziona l'esito">
          </p-dropdown>
        </div>
      </app-form-grid>
    </div>
  </form>
</app-step-wrapper>
