import { EmailContact } from './email-contact.model';
import { PhoneContact } from './phone-contact.model';

export class Contatcts {
  email?: EmailContact[] = [];
  phone?: PhoneContact[] = [];

  constructor(json?: any) {
    if (!!!json) return;
    this.email = (json['email'] || []).map(
      (item: any) => new EmailContact(item)
    );
    this.phone = (json['telefono'] || []).map(
      (item: any) => new PhoneContact(item)
    );
  }

  toJson(): any {
    return {
      email: this.email?.map((e) => e.toJson()),
      telefono: this.phone?.map((t) => t.toJson()),
    };
  }

  getFirtEmailAddress(): string | null {
    if (this.email == null || this.email?.length == 0) return null;
    return this.email[0].address ?? null;
  }

  getFirtEmailAddressEnabled(): boolean | undefined {
    if (this.email == null || this.email?.length == 0) return undefined;
    return this.email[0].enable ?? undefined;
  }

  getFirtPhoneNumber(): string | null {
    if (this.phone == null || this.phone?.length == 0) return null;
    return this.phone[0].phone ?? null;
  }

  getFirtPhoneNumberEnabled(): boolean | undefined {
    if (this.phone == null || this.phone?.length == 0) return undefined;
    return this.phone[0].enable ?? undefined;
  }
}
