<div class="tw-mt-8">
  <app-form-grid *ngIf="user" [formGroup]="$any(user)">
    <!-- BASE DATA -->
    <ng-container>
      <div *ngIf="user.get('__type')?.value !== 'Student'" class="tw-col-span-1 md:tw-col-span-2">
        <app-label>Parentela:</app-label>
        <app-radio-button [xFormGroup]="user" [xFormControlName]="'__type'" [options]="types">
        </app-radio-button>
      </div>
      <div>
        <app-label>Codice Fiscale:</app-label>
        <input pInputText toUppercase formControlName="codice_fiscale" type="text" class="tw-w-full" />
      </div>
      <div>
        <app-label>Cognome:</app-label>
        <input pInputText toTitlecase formControlName="cognome" type="text" class="tw-w-full" />
      </div>
      <div>
        <app-label>Nome:</app-label>
        <input pInputText toTitlecase formControlName="nome" type="text" class="tw-w-full" />
      </div>
      <div>
        <app-label>Secondo Nome:</app-label>
        <input pInputText toTitlecase formControlName="secondo_nome" type="text" class="tw-w-full" />
      </div>
      <div class="tw-hidden">
        <app-label>Sesso:</app-label>
        <app-radio-button [xFormGroup]="user" [xFormControlName]="'sesso'" [options]="genders">
        </app-radio-button>
      </div>
      <div>
        <app-label>Data di Nascita:</app-label>
        <input pInputText formControlName="data_di_nascita" type="date" class="tw-w-full" />
      </div>
      <div>
        <app-label>Luogo di Nascita:</app-label>
        <input pInputText toTitlecase formControlName="luogo_di_nascita" type="text" class="tw-w-full" />
      </div>
      <div>
        <app-label>Provincia di Nascita:</app-label>
        <input pInputText toUppercase formControlName="provincia_di_nascita" type="text" class="tw-w-full" />
      </div>
      <div>
        <app-label>Nazionalità:</app-label>
        <input pInputText toTitlecase formControlName="nazionalita" type="text" class="tw-w-full" />
      </div>
      <!-- <div class="tw-hidden md:tw-block"></div> -->
      <div class="tw-col-span-1 md:tw-col-span-2"><app-hr></app-hr></div>
    </ng-container>
    <!-- BASE DATA -->
    <!-- DOMICILIO / RESIDENZA -->
    <ng-container>
      <div *ngIf="user.get('__type')?.value === 'Student'"
        class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-row tw-justify-between tw-items-center">
        <app-label [ngClass]="'tw-font-medium tw-mb-0'">Domicilio:</app-label>
        <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
          (click)="dataService.copyAddressDataFromTo(user,'residenza','domicilio')">
          <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Copia da residenza</span>
        </button>
      </div>
      <div *ngIf="user.get('__type')?.value !== 'Student'"
        class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
        <app-label [ngClass]="'tw-font-medium'">Domicilio:</app-label>
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-2">
          <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
            (click)="dataService.copyAddressDataFromStudentTo(user,'domicilio')">
            <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Copia da domicilio dello Studente</span>
          </button>
          <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
            (click)="dataService.copyAddressDataFromTo(user,'residenza','domicilio')">
            <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Copia da residenza</span>
          </button>
        </div>
      </div>
      <div formGroupName="domicilio" class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-col tw-gap-8">
        <div class="tw-grid tw-grid-cols-2 md:tw-grid-cols-6 tw-gap-8">
          <div class="tw-col-span-2 md:tw-col-span-4">
            <app-label>Indirizzo:</app-label>
            <input pInputText toTitlecase formControlName="indirizzo" type="text" class="tw-w-full" />
          </div>
          <div>
            <app-label>Civico:</app-label>
            <input pInputText toTitlecase formControlName="civico" type="text" class="tw-w-full" />
          </div>
          <div>
            <app-label>CAP:</app-label>
            <input pInputText toTitlecase formControlName="cap" type="text" class="tw-w-full" />
          </div>
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-8">
          <div>
            <app-label>Città:</app-label>
            <input pInputText toTitlecase formControlName="citta" type="text" class="tw-w-full" />
          </div>
          <div>
            <app-label>Provincia:</app-label>
            <input pInputText toTitlecase formControlName="provincia" type="text" class="tw-w-full" />
          </div>
        </div>
      </div>
      <div class="v-spacer"></div>
      <div *ngIf="user.get('__type')?.value === 'Student'"
        class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-row tw-justify-between tw-items-center">
        <app-label [ngClass]="'tw-font-medium tw-mb-0'">Residenza:</app-label>
        <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
          (click)="dataService.copyAddressDataFromTo(user,'domicilio','residenza')">
          <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Copia da domicilio</span>
        </button>
      </div>
      <div *ngIf="user.get('__type')?.value !== 'Student'"
        class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
        <app-label [ngClass]="'tw-font-medium'">Residenza:</app-label>
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-2">
          <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
            (click)="dataService.copyAddressDataFromStudentTo(user,'residenza')">
            <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Copia da residenza dello Studente</span>
          </button>
          <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
            (click)="dataService.copyAddressDataFromTo(user,'domicilio','residenza')">
            <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Copia da domicilio</span>
          </button>
        </div>
      </div>
      <div formGroupName="residenza" class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-col tw-gap-8">
        <div class="tw-grid tw-grid-cols-2 md:tw-grid-cols-6 tw-gap-8">
          <div class="tw-col-span-2 md:tw-col-span-4">
            <app-label>Indirizzo:</app-label>
            <input pInputText toTitlecase formControlName="indirizzo" type="text" class="tw-w-full" />
          </div>
          <div>
            <app-label>Civico:</app-label>
            <input pInputText toTitlecase formControlName="civico" type="text" class="tw-w-full" />
          </div>
          <div>
            <app-label>CAP:</app-label>
            <input pInputText toTitlecase formControlName="cap" type="text" class="tw-w-full" />
          </div>
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-8">
          <div>
            <app-label>Città:</app-label>
            <input pInputText toTitlecase formControlName="citta" type="text" class="tw-w-full" />
          </div>
          <div>
            <app-label>Provincia:</app-label>
            <input pInputText toTitlecase formControlName="provincia" type="text" class="tw-w-full" />
          </div>
        </div>
      </div>
    </ng-container>
    <!-- DOMICILIO / RESIDENZA -->
    <!-- CONTATTI (E-MAIL / TELEFONO) -->
    <ng-container>
      <div class="tw-hidden md:tw-block"></div>
      <div class="tw-col-span-1 md:tw-col-span-2"><app-hr></app-hr></div>
      <div class="tw-col-span-1 md:tw-col-span-2">
        <app-label [ngClass]="'tw-font-medium'">Lista contatti:</app-label>
      </div>
      <div class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-row tw-justify-between tw-items-center">
        <app-label [ngClass]="'tw-mb-0'">E-Mail:</app-label>
        <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
          (click)="dataService.addContactFields(user,'email')">
          <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Aggiungi indirizzo E-Mail</span>
        </button>
      </div>
      <div class="tw-col-span-none md:tw-col-span-2 tw-flex tw-flex-col md:tw-flex-row tw-gap-4" [formGroup]="$any(e)"
        *ngFor="let e of (dataService.getListOfContacts(user,'email'))?.controls; let ei = index;">
        <div class="tw-col-auto">
          <app-label>Etichetta:</app-label>
          <div class="tw-w-[320px]">
            <app-radio-button [xFormGroup]=" e" [xFormControlName]="'etichetta'" [options]="emailLabels">
            </app-radio-button>
          </div>
        </div>
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-2 lg:tw-pt-6">
          <input formControlName="indirizzo" pInputText toLowercase placeholder="Indirizzo E-Mail" type="text"
            class="tw-w-full" />
          <div class="tw-w-full tw-flex tw-items-start tw-gap-2">
            <p-checkbox formControlName="enable" [binary]="true" inputId="{{'email-'+ei}}"></p-checkbox>
            <label class="tw-ms-1 tw-font-light tw-text-sm" for="{{'email'+ei}}">
              Acconsento all'utilizzo di questo contatto per le comunicazioni scolastiche
            </label>
          </div>
        </div>
        <div
          class="tw-col-auto tw-mt-0 md:tw-mt-1 tw-flex tw-flex-row md:tw-flex-col tw-justify-between tw-flex-nowrap tw-gap-4 md:tw-pt-1 lg:tw-pt-7">
          <button pButton class="p-button-danger p-button-outlined tw-py-2 tw-px-4 tw-text-sm" type="button"
            (click)="dataService.removeContactFields(user,'email',ei)">
            <span class="tw-py-1 tw-px-2 tw-text-sm" style="color: inherit;">Elimina</span>
          </button>
        </div>
      </div>
      <div class="v-spacer"></div>
      <div class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-row tw-justify-between tw-items-center">
        <app-label [ngClass]="'tw-mb-0'">Telefono:</app-label>
        <button pButton class="p-button-primary p-button-outlined tw-flex tw-justify-center" type="button"
          (click)="dataService.addContactFields(user,'telefono')">
          <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Aggiungi numero di telefono</span>
        </button>
      </div>
      <div class="tw-col-span-none lg:tw-col-span-2 tw-flex tw-flex-col lg:tw-flex-row tw-gap-4" [formGroup]="$any(t)"
        *ngFor="let t of (dataService.getListOfContacts(user,'telefono')).controls; let ti = index;">
        <div class="tw-col-auto">
          <app-label>Etichetta:</app-label>
          <div class="tw-w-[320px]">
            <app-radio-button [xFormGroup]="t" [xFormControlName]="'etichetta'" [options]="phoneLabels">
            </app-radio-button>
          </div>
        </div>
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-2 lg:tw-pt-6">
          <div class="tw-w-full tw-flex tw-items-end tw-gap-2">
            <div class="w-[150px]">
              <p-dropdown [classList]="'tw-w-full'" formControlName="prefisso" [options]="phonePrefixesOptions"
                placeholder="Seleziona il prefisso" dataKey="code" optionLabel="name" optionValue="value">
              </p-dropdown>
            </div>
            <input formControlName="numero" pInputText toLowercase placeholder="Numero di telefono" type="text"
              class="tw-w-full" />
          </div>
          <div class="tw-w-full tw-flex tw-items-start tw-gap-2">
            <p-checkbox formControlName="enable" [binary]="true" inputId="{{'telefono-'+ti}}"></p-checkbox>
            <label class="tw-ms-1 tw-font-light tw-text-sm" for="{{'telefono'+ti}}">
              Acconsento all'utilizzo di questo contatto per le comunicazioni scolastiche
            </label>
          </div>
        </div>
        <div
          class="tw-col-auto tw-mt-0 md:tw-mt-1 tw-flex tw-flex-row tw-justify-between tw-items-center tw-flex-nowrap tw-gap-4 lg:tw-pb-2">
          <button pButton class="p-button-danger p-button-outlined tw-py-2 tw-px-4 tw-text-sm" type="button"
            (click)="dataService.removeContactFields(user,'telefono',ti)">
            <span class="tw-py-1 tw-px-2 tw-text-sm" style="color: inherit;">Elimina</span>
          </button>
        </div>
      </div>
    </ng-container>
    <!-- CONTATTI (E-MAIL / TELEFONO) -->
    <!-- CARTA D'IDENTITÀ -->
    <ng-container>
      <div class="tw-hidden lg:tw-block"></div>
      <div class="tw-col-span-1 md:tw-col-span-2">
        <app-hr></app-hr>
      </div>
      <div class="tw-col-span-1 md:tw-col-span-2">
        <app-label [ngClass]="'tw-font-medium'">Carta d'identità:</app-label>
      </div>
      <app-img-upload [inputBase64]="user.get('ci')?.get('fronte')?.value"
        (outputBbase64)="dataService.setDocument(user,'fronte',$event)"
        (deleteImg)="dataService.removeDocument(user,'fronte')">
        <div><strong>Fronte</strong> Carta d'identità</div>
      </app-img-upload>
      <app-img-upload [inputBase64]="user.get('ci')?.get('retro')?.value"
        (outputBbase64)="dataService.setDocument(user,'retro',$event)"
        (deleteImg)="dataService.removeDocument(user,'retro')">
        <div><strong>Retro</strong> Carta d'identità</div>
      </app-img-upload>
    </ng-container>
    <!-- CARTA D'IDENTITÀ -->
    <!-- ELIMINA UTENTE -->
    <ng-container *ngIf="user.get('__type')?.value !== 'Student'">
      <div class="tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-row tw-justify-center">
        <button pButton class="p-button-danger p-button-outlined tw-flex tw-justify-center" type="button"
          (click)="dataService.removeUser(user.get('__uuid')?.value)">
          <span class="tw-p-4" style="color: inherit;">
            <strong style="color: inherit;">
              Rimuovi {{user.get('__type')?.value=='Parent'?'Genitore':user.get('__type')?.value=='Tutor'?'Tutore':''}}
            </strong>
          </span>
        </button>
      </div>
    </ng-container>
    <!-- ELIMNA UTENTE -->
  </app-form-grid>
</div>
