import { PhonePrefix } from '../models/phone-prefix.interface';
import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  public phonePrefixes: PhonePrefix[] = [];
  constructor(private http: HttpClient) {
    if (this.phonePrefixes.length === 0) {
      this.getPhonePrefixes().subscribe({
        next: (response) => (this.phonePrefixes = response.data),
        error: (err) => {
          console.error('ToolsService - getPhonePrefixes Error:', err);
        },
      });
    }
  }

  getDataFromCF(cf?: string) {
    return this.http.get<ApiResponse>(API.dataFromCF(cf), {
      withCredentials: true,
    });
  }

  getPhonePrefixes() {
    return this.http.get<ApiResponse>(API.phonePrefixes(), {
      withCredentials: false,
    });
  }
}
