export class PhoneContact {
  label?: string;
  phone?: string;
  enable?: boolean;

  constructor(json?: any) {
    if (!!!json) return;
    this.label = json['etichetta'];
    this.phone = json['numero'];
    this.enable =
      json['enable'] === undefined
        ? true
        : (json['enable'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
  }

  toJson(): any {
    if (!this.label && !this.phone && this.enable === undefined) {
      return undefined;
    }
    return { etichetta: this.label, numero: this.phone, enable: this.enable };
  }

  getPhoneContactString(): string {
    return `${this.label} ${this.phone}`.toString().trim();
  }
}
