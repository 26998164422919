import { environment } from 'src/environments/environment';

export class API {
  // TOOLS
  static dataFromCF(cf?: string): string {
    let url = `${environment.apiUrl}/v1/tools/cf/data?cf=${cf || 'CF-ERROR'}`;
    return url;
  }
  static phonePrefixes(): string {
    return `${environment.apiUrl}/v1/tools/phone-prefixes`;
  }
}
