import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-wrapper',
  template: `<div
    class="tw-w-full tw-h-full tw-shadow-md tw-rounded-md tw-p-4 md:tw-p-8 tw-bg-white tw-border tw-border-neutral-50"
  >
    <app-step-block-title>{{ title || '' }}</app-step-block-title>
    <ng-content></ng-content>
    <app-step-block-controller></app-step-block-controller>
  </div>`,
})
export class StepWrapperComponent {
  @Input() title: string | undefined;
}
