import { Contatcts } from './contatcts.model';
import { Address } from './address.model';
import { UserType } from './user-type';

export class User {
  id?: string;
  type?: UserType;
  sede?: string;
  dea_id?: string;
  nome?: string;
  secondo_nome?: string;
  cognome?: string;
  codice_fiscale?: string;
  sesso?: string;
  data_di_nascita?: Date;
  luogo_di_nascita?: string;
  provincia_di_nascita?: string;
  nazionalita?: string;
  domicilio?: Address;
  residenza?: Address;
  contatti?: Contatcts;

  constructor(json?: any) {
    if (!!!json) return;
    this.id = json['_id'] || json['id'];
    this.type = json['__type']
      ? UserType.fromString(json['__type'])
      : undefined;
    this.sede = json['sede'];
    this.dea_id = json['dea_id'];
    this.nome = json['nome'];
    this.secondo_nome = json['secondo_nome'];
    this.cognome = json['cognome'];
    this.codice_fiscale = json['codice_fiscale'];
    this.sesso = json['sesso'];
    this.data_di_nascita = json['data_di_nascita']
      ? new Date(json['data_di_nascita'])
      : undefined;
    this.luogo_di_nascita = json['luogo_di_nascita'];
    this.provincia_di_nascita = json['provincia_di_nascita'];
    this.nazionalita = json['nazionalita'];
    this.domicilio = new Address(json['domicilio']);
    this.residenza = new Address(json['residenza']);
    this.contatti = new Contatcts(json['contatti']);
  }

  toJson(defaultUndefined: boolean = true): any {
    const value = defaultUndefined ? undefined : '';
    let json = {
      _id: this.id,
      __type: this.type?.backend || value,
      dea_id: this.dea_id?.toUpperCase() || value,
      cognome: this.cognome || value,
      nome: this.nome || value,
      secondo_nome: this.secondo_nome || value,
      codice_fiscale: this.codice_fiscale?.toUpperCase() || value,
      sesso: this.sesso?.toUpperCase() || value,
      data_di_nascita:
        this.data_di_nascita?.toISOString().slice(0, 10) || undefined,
      luogo_di_nascita: this.luogo_di_nascita || value,
      provincia_di_nascita: this.provincia_di_nascita?.toUpperCase() || value,
      nazionalita: this.nazionalita || value,
      domicilio: this.domicilio?.toJson() || value,
      residenza: this.residenza?.toJson() || value,
      contatti: this.contatti?.toJson() || value,
    };
    if (json.data_di_nascita === new Date().toISOString().slice(0, 10)) {
      console.log('BUG SAFARI');
      // Fix bug Safari
      // Se la data è uguale a quella di oggi => È stata impostata da Safari
      // Se la data è stata impostata da Safari => Imposto la data al valore stabilit
      json.data_di_nascita = undefined;
    }
    return json;
  }

  getFullName(): string {
    return `${this.cognome} ${this.nome} ${this.secondo_nome}`
      .replaceAll('undefined', '')
      .trim();
  }

  isStudent(): boolean {
    return this.type && this.type === UserType.STUDENT ? true : false;
  }
}
