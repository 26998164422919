<app-navbar></app-navbar>
<p-toast position="top-right" key="app-toast"></p-toast>
<div style="min-height: var(--dea-wrapper-height);">
  <app-container>
    <app-step-progress-bar></app-step-progress-bar>
    <div class="tw-py-8 tw-pt-12" [ngSwitch]="index">
      <app-step-a *ngSwitchCase="0"></app-step-a>
      <app-step-b *ngSwitchCase="1"></app-step-b>
      <app-step-c *ngSwitchCase="2"></app-step-c>
      <app-step-d *ngSwitchCase="3"></app-step-d>
      <app-step-e *ngSwitchCase="4"></app-step-e>
    </div>
  </app-container>
</div>
<app-footer></app-footer>