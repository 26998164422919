import { INFO } from 'src/environments/info';
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  get hash(): string {
    return INFO?.hash || '';
  }
}
