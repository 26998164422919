import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { Component } from '@angular/core';

@Component({
  selector: 'app-photo-capture-dialog',
  templateUrl: './photo-capture-dialog.component.html',
  styleUrls: ['./photo-capture-dialog.component.scss'],
})
export class PhotoCaptureDialogComponent {
  image: WebcamImage | undefined;
  initErrorMsg?: string | undefined;

  videoOptions: MediaTrackConstraints = {
    facingMode: 'environment',
    width: { min: 1280 },
    height: { min: 720 },
    aspectRatio: 16 / 9,
  };

  constructor(
    public ref: DynamicDialogRef,
    private _messageService: MessageService
  ) {}

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError) {
      switch (error.mediaStreamError.name) {
        case 'NotAllowedError':
          this.initErrorMsg =
            "L'accesso alla telecamera non è stato consentito dall'utente!";
          break;
        default:
          this.initErrorMsg =
            "Non è stato possibile accedere alla fotocamera dell'utente!";
          break;
      }
      this._messageService.add({
        key: 'app-toast',
        severity: 'error',
        summary: 'Errore:',
        detail: this.initErrorMsg,
      });
      console.warn(this.initErrorMsg);
      this.close();
    } else this.initErrorMsg = undefined;
  }

  private trigger: Subject<void> = new Subject<void>();

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.image = webcamImage;
  }

  close(result?: any): void {
    this.ref.close(result);
  }
}
