import { DataService } from 'src/app/services/data.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-step-b',
  templateUrl: './step-b.component.html',
  styleUrls: ['./step-b.component.scss'],
})
export class StepBComponent {
  get dataService(): DataService {
    return this._dataService;
  }

  constructor(private _dataService: DataService) {
    if (this._dataService.enrollmentFormStudentUsers?.length === 0) {
      this._dataService.pushUser('Student');
    }
  }
}
