<div>
  <div class="tw-w-full tw-rounded-sm tw-flex tw-flex-row tw-justify-between tw-items-center tw-aspect-video">
    <webcam *ngIf="!image" [width]="1920" [height]="1080" [imageQuality]="1" [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)" (initError)="handleInitError($event)" [videoOptions]="videoOptions">
    </webcam>
    <img *ngIf="image" src="{{image.imageAsDataUrl}}" alt="New Image">
  </div>
  <div class="tw-mt-4 tw-flex tw-flex-row tw-justify-end tw-gap-2">
    <button autofocus class="tw-hidden">autofocus</button>
    <button pButton class="p-button-secondary p-button-outlined tw-py-2 tw-px-4 tw-text-sm" type="button"
      (click)="close()">
      <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">Annulla</span>
    </button>
    <button pButton class="p-button-primary tw-py-2 tw-px-4 tw-text-sm" type="button"
      (click)="image?close(image.imageAsDataUrl):triggerSnapshot()">
      <span class="tw-py-2 tw-px-4 tw-text-sm" style="color: inherit;">
        {{image?'Salva':'Cattura'}}
      </span>
    </button>
  </div>
</div>