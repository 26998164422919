import { FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { RadioButtonInterface } from '../models/radio.interface';
import { Enrollment } from '../models/enrollment.model';
import { CfData } from '../models/cf-data.model';
import { ToolsService } from './tools.service';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  UserType,
  AddressType,
  ContactType,
  DocumentSide,
  EmailContactLabel,
  PhoneContactLabel,
} from '../types/types';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private cfChecking: boolean = false;
  private cfSubscriptions: Subscription[] = [];
  private _baseAcademicYearsListing: RadioButtonInterface[] = [
    { value: '1', label: '1°' },
    { value: '2', label: '2°' },
    { value: '3', label: '3°' },
    { value: '4', label: '4°' },
    { value: '5', label: '5°' },
  ];
  private readonly _options = [
    {
      value: 'MILANO',
      label: 'Istituti E. De Amicis - Milano',
      description: 'Via Lamarmora, 34 - 20122, Milano (MI)',
      departmentsListing: [
        {
          title: 'Secondaria di I Grado',
          academicYearsListing: [
            { value: '1', label: '1°' },
            { value: '2', label: '2°' },
            { value: '3', label: '3°' },
          ],
        },
        {
          title: 'Liceo delle Scienze Umane',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico Sportivo',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Linguistico Internazionale',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Classico',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'ITEM',
          academicYearsListing: [
            { value: '1', label: '1°' },
            { value: '2', label: '2°' },
            { value: '3', label: '3°' },
            { value: '4', label: '4°' },
          ],
        },
      ],
    },
    {
      value: 'GORGONZOLA',
      label: 'Licei Santagostino - Gorgonzola',
      description: 'Via Matteotti, 30 - 20064, Gorgonzola (MI)',
      departmentsListing: [
        {
          title: 'Liceo Linguistico Internazionale',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico Sportivo',
          academicYearsListing: this._baseAcademicYearsListing,
        },
      ],
    },
  ];
  enrollment: Enrollment = new Enrollment();
  index: number = 0;
  steps: readonly string[] = [
    'Configurazione',
    'Studente',
    'Genitori | Tutori',
    'Finalizzazione',
    'Anteprima',
  ];

  enrollmentForm: FormGroup = new FormGroup({
    sede: new FormControl(),
    anno_scolastico: new FormControl(),
    dipartimento: new FormControl({ value: '', disabled: true }),
    anno: new FormControl({ value: '', disabled: true }),
    users: new FormArray([]),
    religione: new FormControl(),
    dea_sport: new FormControl(),
    regolamento: new FormControl(),
    prima_lingua: new FormControl('Inglese'),
    seconda_lingua: new FormControl(),
    terza_lingua: new FormControl(),
    privacy_policy_1: new FormControl(),
    privacy_policy_2: new FormControl(),
    privacy_policy_3: new FormControl(),
    ultimo_anno: new FormGroup({
      anno_scolastico: new FormControl(),
      istituto: new FormControl(),
      tipo: new FormControl(),
      dettaglio: new FormControl({ value: '', disabled: true }),
      esito: new FormControl({ value: '', disabled: true }),
    }),
  });

  get enrollmentFormUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls;
  }

  get enrollmentFormStudentUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Student'
    );
  }

  get enrollmentFormNotStudentUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value !== 'Student'
    );
  }

  get headquartersListing() {
    return this._options;
  }

  public dynamicDepartmentsListing: string[] = [];

  public dynamicAcademicYearsListing = this._baseAcademicYearsListing;

  constructor(private _toolsService: ToolsService) {
    this.enrollmentForm
      .get('ultimo_anno')
      ?.get('tipo')
      ?.valueChanges.subscribe(() => {
        if (this.enrollmentForm.get('ultimo_anno')?.get('tipo')?.value) {
          this.enrollmentForm?.get('ultimo_anno')?.get('dettaglio')?.enable();
          this.enrollmentForm?.get('ultimo_anno')?.get('esito')?.enable();
        }
      });

    this.enrollmentForm?.get('sede')?.valueChanges.subscribe(() => {
      if (this.enrollmentForm.get('sede')?.value) {
        const departments =
          this._options
            .filter(
              (obj) => obj.value === this.enrollmentForm.get('sede')?.value
            )
            .at(0)
            ?.departmentsListing?.map((obj) => obj.title) || [];
        this.dynamicDepartmentsListing = departments;
        this.enrollmentForm?.get('dipartimento')?.enable();
        this.enrollmentForm?.get('anno')?.enable();
      } else {
        this.enrollmentForm
          ?.get('dipartimento')
          ?.setValue({ value: '', disabled: true });
        this.enrollmentForm
          ?.get('anno')
          ?.setValue({ value: '', disabled: true });
      }
    });

    this.enrollmentForm
      ?.get('dipartimento')
      ?.valueChanges.subscribe((value: any) => {
        if (value === 'Liceo Linguistico Internazionale') {
          this.enrollmentForm.get('seconda_lingua')?.setValue(null);
          this.enrollmentForm.get('terza_lingua')?.setValue('Spagnolo');
        } else if (value === 'Secondaria di I Grado') {
          this.enrollmentForm.get('terza_lingua')?.setValue(null);
          this.enrollmentForm.get('seconda_lingua')?.setValue('Spagnolo');
        } else {
          this.enrollmentForm.get('terza_lingua')?.setValue(null);
          this.enrollmentForm.get('seconda_lingua')?.setValue(null);
        }

        if (this.enrollmentForm.get('dipartimento')?.value) {
          const academicYears =
            this._options
              .filter(
                (obj) => obj.value === this.enrollmentForm.get('sede')?.value
              )
              .at(0)
              ?.departmentsListing?.filter(
                (obj) =>
                  obj.title === this.enrollmentForm.get('dipartimento')?.value
              )
              .at(0)?.academicYearsListing || [];
          this.dynamicAcademicYearsListing = academicYears;
          this.enrollmentForm?.get('anno')?.enable();
        } else {
          this.enrollmentForm
            ?.get('anno')
            ?.setValue({ value: '', disabled: true });
        }
      });
  }

  canGoToPrevIndex(): boolean {
    return this.index > 0;
  }

  canGoToNextIndex(): boolean {
    return this.index < this.steps.length - 1;
  }

  goToPrevIndex(): void {
    this.index -= 1;
  }

  goToNextIndex(): void {
    this.index += 1;
  }

  private isPrinting: boolean = false;
  printForm(delay: number = 500) {
    if (this.isPrinting) return;
    this.isPrinting = !this.isPrinting;
    setTimeout(() => {
      this.isPrinting = !this.isPrinting;
      console.info('ENROLLMENT-FORM:', this.enrollmentForm.value);
    }, delay);
  }

  getBaseUserForm(type: UserType): FormGroup {
    return new FormGroup({
      __uuid: new FormControl(uuidv4()),
      __type: new FormControl(type, [Validators.required]),
      cognome: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(2),
      ]),
      nome: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(2),
      ]),
      secondo_nome: new FormControl(),
      codice_fiscale: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(16),
      ]),
      sesso: new FormControl(),
      data_di_nascita: new FormControl(),
      luogo_di_nascita: new FormControl(),
      provincia_di_nascita: new FormControl(),
      nazionalita: new FormControl(),
      domicilio: new FormGroup({
        indirizzo: new FormControl(),
        civico: new FormControl(),
        cap: new FormControl(),
        citta: new FormControl(),
        provincia: new FormControl(),
      }),
      residenza: new FormGroup({
        indirizzo: new FormControl(),
        civico: new FormControl(),
        cap: new FormControl(),
        citta: new FormControl(),
        provincia: new FormControl(),
      }),
      contatti: new FormGroup({
        email: new FormArray([]),
        telefono: new FormArray<any>([]),
      }),
      ci: new FormGroup({
        fronte: new FormControl(),
        retro: new FormControl(),
      }),
    });
  }

  pushUser(userType: UserType) {
    this.removeCfListeners();
    (this.enrollmentForm.controls['users'] as FormArray).push(
      this.getBaseUserForm(userType)
    );
    this.setCfListeners();
  }

  removeUser(userUUID: string) {
    const index: number = this.enrollmentFormUsers?.findIndex(
      (user) => user.get('__uuid')?.value === userUUID
    );
    if (index !== -1) this.enrollmentFormUsers.splice(index, 1);
  }

  setCfListeners(): void {
    this.enrollmentFormUsers?.map((user, i) => {
      let sub = user.get('codice_fiscale')?.valueChanges.subscribe(() => {
        if (user.get('codice_fiscale')?.value.length === 16) {
          const cf = user.get('codice_fiscale')?.value;
          if (!this.cfChecking) {
            this.cfChecking = true;
            this._toolsService
              .getDataFromCF(cf)
              .subscribe({
                error: (e) =>
                  console.error(
                    `Data from CF for user at index ${i} error:`,
                    e
                  ),
                next: (response) => {
                  if (response?.data?.dati) {
                    const cfData: CfData = new CfData(response.data);
                    this.setDataFromCf(
                      this.enrollmentFormUsers?.at(i) as FormGroup,
                      cfData
                    );
                  }
                },
              })
              .add(() => (this.cfChecking = false));
          }
        }
      });
      if (sub) this.cfSubscriptions.push(sub);
    });
  }

  removeCfListeners() {
    this.cfSubscriptions.map((sub) => sub.unsubscribe());
    this.cfSubscriptions = [];
  }

  getUserByUUID(userUUID: string): FormGroup {
    return this.enrollmentFormUsers
      ?.filter((user) => user.get('__uuid')?.value === userUUID)
      ?.at(0) as FormGroup;
  }

  setDataFromCf(user: FormGroup, data: CfData) {
    user.get('sesso')?.setValue(data.gender);
    user.get('nazionalita')?.setValue('Italiana');
    user.get('luogo_di_nascita')?.setValue(data.birthplace);
    user.get('provincia_di_nascita')?.setValue(data.birthplaceProvincia);
    user
      .get('data_di_nascita')
      ?.setValue(
        data?.birthday
          ? new Date(data.birthday)?.toISOString()?.slice(0, 10)
          : undefined
      );
  }

  copyAddressDataFromTo(
    user: FormGroup,
    fromPath: AddressType,
    toPath: AddressType
  ) {
    user.get(toPath)?.setValue(user.get(fromPath)?.getRawValue());
  }

  copyAddressDataFromStudentTo(user: FormGroup, path: AddressType) {
    user
      .get(path)
      ?.setValue(
        this.enrollmentFormStudentUsers?.at(0)?.get(path)?.getRawValue()
      );
  }

  getListOfContacts(user: FormGroup, type: ContactType): FormArray {
    return user.get('contatti')?.get(type) as FormArray;
  }

  addContactFields(user: FormGroup, type: ContactType) {
    (user.get('contatti')?.get(type) as FormArray)?.push(
      type === 'email'
        ? new FormGroup({
            etichetta: new FormControl(),
            indirizzo: new FormControl(),
            enable: new FormControl(true),
          })
        : new FormGroup({
            etichetta: new FormControl(),
            numero: new FormControl(),
            prefisso: new FormControl('+39'),
            enable: new FormControl(true),
          })
    );
  }

  removeContactFields(
    user: FormGroup,
    type: ContactType,
    contactIndex: number
  ) {
    (user.get('contatti')?.get(type) as FormArray)?.removeAt(contactIndex);
  }

  setContactLabel(
    user: FormGroup,
    contactType: ContactType,
    contactIndex: number,
    label: EmailContactLabel | PhoneContactLabel
  ) {
    (user.get('contatti')?.get(contactType) as FormArray)
      ?.at(contactIndex)
      ?.get('etichetta')
      ?.setValue(label);
  }

  setDocument(user: FormGroup, documentSide: DocumentSide, data: string) {
    user.get('ci')?.get(documentSide)?.setValue(data);
  }

  removeDocument(user: FormGroup, documentSide: DocumentSide) {
    user.get('ci')?.get(documentSide)?.setValue(undefined);
  }
}
