import { RadioButtonInterface } from '../models/radio.interface';
import { Enrollment } from '../models/enrollment.model';
import { inject, Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CfData } from '../models/cf-data.model';
import { ToolsService } from './tools.service';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  FormArray,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import {
  UserType,
  AddressType,
  ContactType,
  DocumentSide,
  EmailContactLabel,
  PhoneContactLabel,
} from '../types/types';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private cfChecking: boolean = false;
  private cfSubscriptions: Subscription[] = [];
  private _confirmationService = inject(ConfirmationService);
  private _toolsService: ToolsService = inject(ToolsService);
  private _baseAcademicYearsListing: RadioButtonInterface[] = [
    { value: '1', label: '1°' },
    { value: '2', label: '2°' },
    { value: '3', label: '3°' },
    { value: '4', label: '4°' },
    { value: '5', label: '5°' },
  ];
  private readonly _options = [
    {
      value: 'MILANO',
      label: 'Istituti E. De Amicis - Milano',
      description: 'Via Lamarmora, 34 - 20122, Milano (MI)',
      departmentsListing: [
        {
          title: 'Secondaria di I Grado',
          academicYearsListing: [
            { value: '1', label: '1°' },
            { value: '2', label: '2°' },
            { value: '3', label: '3°' },
          ],
        },
        {
          title: 'Liceo delle Scienze Umane',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico Sportivo',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Linguistico Internazionale',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Classico',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'ITEM',
          academicYearsListing: [
            { value: '1', label: '1°' },
            { value: '2', label: '2°' },
            { value: '3', label: '3°' },
            { value: '4', label: '4°' },
          ],
        },
      ],
    },
    {
      value: 'GORGONZOLA',
      label: 'Licei Santagostino - Gorgonzola',
      description: 'Via Matteotti, 30 - 20064, Gorgonzola (MI)',
      departmentsListing: [
        {
          title: 'Liceo Linguistico Internazionale',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico Sportivo',
          academicYearsListing: this._baseAcademicYearsListing,
        },
      ],
    },
  ];
  enrollment: Enrollment = new Enrollment();
  index: number = 0;
  steps: readonly string[] = [
    'Configurazione',
    'Studente',
    'Genitori | Tutori',
    'Finalizzazione',
    'Anteprima',
  ];

  enrollmentForm: FormGroup = new FormGroup({
    sede: new FormControl(),
    anno_scolastico: new FormControl(),
    dipartimento: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    anno: new FormControl({ value: 1, disabled: true }),
    users: new FormArray([]),
    religione: new FormControl(),
    dea_sport: new FormControl(),
    regolamento: new FormControl(),
    prima_lingua: new FormControl('Inglese'),
    seconda_lingua: new FormControl(),
    terza_lingua: new FormControl(),
    privacy_policy_1: new FormControl(),
    privacy_policy_2: new FormControl(),
    privacy_policy_3: new FormControl(),
    ultimo_anno: new FormGroup({
      anno_scolastico: new FormControl(),
      istituto: new FormControl(),
      tipo: new FormControl(),
      dettaglio: new FormControl({ value: '', disabled: true }),
      esito: new FormControl({ value: '', disabled: true }),
    }),
  });

  get enrollmentFormUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls;
  }

  get enrollmentFormStudentUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Student'
    );
  }

  get enrollmentFormNotStudentUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value !== 'Student'
    );
  }

  get enrollmentFormParentUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Parent'
    );
  }

  get enrollmentFormTutorUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Tutor'
    );
  }

  get enrollmentFormParentUsersNumber() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Parent'
    ).length;
  }

  get enrollmentFormTutorNumber() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Tutor'
    ).length;
  }

  get headquartersListing() {
    return this._options;
  }

  public dynamicDepartmentsListing: string[] = [];

  public dynamicAcademicYearsListing = this._baseAcademicYearsListing;

  constructor() {
    this.initializeEnrollmentFormSession();
  }

  canGoToPrevIndex(): boolean {
    return this.index > 0;
  }

  canGoToNextIndex(): boolean {
    return this.index < this.steps.length - 1;
  }

  goToPrevIndex(): void {
    this.index -= 1;
  }

  goToNextIndex(): void {
    this.index += 1;
  }

  private isPrinting: boolean = false;
  printForm(delay: number = 500) {
    if (this.isPrinting) return;
    this.isPrinting = !this.isPrinting;
    setTimeout(() => {
      this.isPrinting = !this.isPrinting;
      console.info('ENROLLMENT-FORM:', this.enrollmentForm.value);
    }, delay);
  }

  getBaseUserForm(type: UserType): FormGroup {
    return new FormGroup({
      __uuid: new FormControl(uuidv4()),
      __type: new FormControl(type, [Validators.required]),
      cognome: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(2),
      ]),
      nome: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(2),
      ]),
      secondo_nome: new FormControl(),
      codice_fiscale: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(16),
      ]),
      sesso: new FormControl(undefined, Validators.required),
      data_di_nascita: new FormControl(undefined, Validators.required),
      luogo_di_nascita: new FormControl(undefined, Validators.required),
      provincia_di_nascita: new FormControl(undefined, Validators.required),
      nazionalita: new FormControl(undefined, Validators.required),
      domicilio: new FormGroup({
        indirizzo: new FormControl(undefined, Validators.required),
        civico: new FormControl(undefined, Validators.required),
        cap: new FormControl(undefined, Validators.required),
        citta: new FormControl(undefined, Validators.required),
        provincia: new FormControl(undefined, Validators.required),
      }),
      residenza: new FormGroup({
        indirizzo: new FormControl(undefined, Validators.required),
        civico: new FormControl(undefined, Validators.required),
        cap: new FormControl(undefined, Validators.required),
        citta: new FormControl(undefined, Validators.required),
        provincia: new FormControl(undefined, Validators.required),
      }),
      contatti: new FormGroup({
        email: new FormArray([]),
        telefono: new FormArray<any>([]),
      }),
      ci: new FormGroup({
        fronte: new FormControl(),
        retro: new FormControl(),
      }),
    });
  }

  pushUser(userType: UserType) {
    this.removeCfListeners();
    (this.enrollmentForm.controls['users'] as FormArray).push(
      this.getBaseUserForm(userType)
    );

    if (this.enrollmentFormParentUsersNumber >= 2) {
      const tutorsArray = this.enrollmentFormTutorUsers;
      tutorsArray.forEach((tutor: any) => tutor?.get('__type')?.disable());
    }
    if (this.enrollmentFormTutorNumber >= 2) {
      const parentsArray = this.enrollmentFormParentUsers;
      parentsArray.forEach((parent: any) => parent?.get('__type')?.disable());
    }

    this.setCfListeners();
  }

  removeUser(userUUID: string) {
    const index: number = this.enrollmentFormUsers?.findIndex(
      (user) => user.get('__uuid')?.value === userUUID
    );
    if (index !== -1) this.enrollmentFormUsers.splice(index, 1);
  }

  setCfListeners(): void {
    this.enrollmentFormUsers?.map((user, i) => {
      let sub = user.get('codice_fiscale')?.valueChanges.subscribe(() => {
        if (user.get('codice_fiscale')?.value.length === 16) {
          const cf = user.get('codice_fiscale')?.value;
          if (!this.cfChecking) {
            this.cfChecking = true;
            this._toolsService
              .getDataFromCF(cf)
              .subscribe({
                error: (e) =>
                  console.error(
                    `Data from CF for user at index ${i} error:`,
                    e
                  ),
                next: (response) => {
                  if (response?.data?.dati) {
                    const cfData: CfData = new CfData(response.data);
                    this.setDataFromCf(
                      this.enrollmentFormUsers?.at(i) as FormGroup,
                      cfData
                    );
                  }
                },
              })
              .add(() => (this.cfChecking = false));
          }
        }
      });
      if (sub) this.cfSubscriptions.push(sub);
    });
  }

  removeCfListeners() {
    this.cfSubscriptions.map((sub) => sub.unsubscribe());
    this.cfSubscriptions = [];
  }

  getUserByUUID(userUUID: string): FormGroup {
    return this.enrollmentFormUsers
      ?.filter((user) => user.get('__uuid')?.value === userUUID)
      ?.at(0) as FormGroup;
  }

  setDataFromCf(user: FormGroup, data: CfData) {
    user.get('sesso')?.setValue(data.gender);
    // user.get('nazionalita')?.setValue('Italiana');
    user.get('luogo_di_nascita')?.setValue(data.birthplace);
    user.get('provincia_di_nascita')?.setValue(data.birthplaceProvincia);
    user
      .get('data_di_nascita')
      ?.setValue(
        data?.birthday
          ? new Date(data.birthday)?.toISOString()?.slice(0, 10)
          : undefined
      );
  }

  copyAddressDataFromTo(
    user: FormGroup,
    fromPath: AddressType,
    toPath: AddressType
  ) {
    user.get(toPath)?.setValue(user.get(fromPath)?.getRawValue());
  }

  copyAddressDataFromStudentTo(user: FormGroup, path: AddressType) {
    user
      .get(path)
      ?.setValue(
        this.enrollmentFormStudentUsers?.at(0)?.get(path)?.getRawValue()
      );
  }

  getListOfContacts(user: FormGroup, type: ContactType): FormArray {
    return user.get('contatti')?.get(type) as FormArray;
  }

  addContactFields(user: FormGroup, type: ContactType) {
    (user.get('contatti')?.get(type) as FormArray)?.push(
      type === 'email'
        ? new FormGroup({
            etichetta: new FormControl('Personale'),
            indirizzo: new FormControl(undefined, [
              Validators.required,
              Validators.email,
              Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
            ]),
            enable: new FormControl(true),
          })
        : new FormGroup({
            etichetta: new FormControl('Cellulare'),
            numero: new FormControl(undefined, Validators.required),
            prefisso: new FormControl('+39'),
            enable: new FormControl(true),
          })
    );
  }

  removeContactFields(
    user: FormGroup,
    type: ContactType,
    contactIndex: number
  ) {
    (user.get('contatti')?.get(type) as FormArray)?.removeAt(contactIndex);
  }

  setContactLabel(
    user: FormGroup,
    contactType: ContactType,
    contactIndex: number,
    label: EmailContactLabel | PhoneContactLabel
  ) {
    (user.get('contatti')?.get(contactType) as FormArray)
      ?.at(contactIndex)
      ?.get('etichetta')
      ?.setValue(label);
  }

  setDocument(user: FormGroup, documentSide: DocumentSide, data: string) {
    user.get('ci')?.get(documentSide)?.setValue(data);
  }

  removeDocument(user: FormGroup, documentSide: DocumentSide) {
    user.get('ci')?.get(documentSide)?.setValue(undefined);
  }

  initializeEnrollmentFormSession(): void {
    // Imposto il primo step;
    this.index = 0;
    // Imposto i valori di default;
    this.enrollmentForm = new FormGroup({
      sede: new FormControl(undefined, Validators.required),
      anno_scolastico: new FormControl(undefined, Validators.required),
      dipartimento: new FormControl(
        { value: undefined, disabled: true },
        Validators.required
      ),
      anno: new FormControl({ value: '', disabled: true }, Validators.required),
      users: new FormArray([]),
      religione: new FormControl(undefined, Validators.required),
      dea_sport: new FormControl(undefined, Validators.required),
      regolamento: new FormControl(undefined, Validators.required),
      prima_lingua: new FormControl('Inglese'),
      seconda_lingua: new FormControl(),
      terza_lingua: new FormControl(),
      privacy_policy_1: new FormControl(),
      privacy_policy_2: new FormControl(),
      privacy_policy_3: new FormControl(),
      ultimo_anno: new FormGroup({
        anno_scolastico: new FormControl(undefined, Validators.required),
        istituto: new FormControl(undefined, Validators.required),
        tipo: new FormControl(undefined, Validators.required),
        dettaglio: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
        esito: new FormControl({ value: '', disabled: true }),
      }),
    });
    // Imposto i listeners;
    this.enrollmentForm
      .get('ultimo_anno')
      ?.get('tipo')
      ?.valueChanges.subscribe(() => {
        if (this.enrollmentForm.get('ultimo_anno')?.get('tipo')?.value) {
          this.enrollmentForm?.get('ultimo_anno')?.get('dettaglio')?.enable();
          this.enrollmentForm?.get('ultimo_anno')?.get('esito')?.enable();
        }
      });

    this.enrollmentForm?.get('sede')?.valueChanges.subscribe(() => {
      if (this.enrollmentForm.get('sede')?.value) {
        const departments =
          this._options
            .filter(
              (obj) => obj.value === this.enrollmentForm.get('sede')?.value
            )
            .at(0)
            ?.departmentsListing?.map((obj) => obj.title) || [];
        this.dynamicDepartmentsListing = departments;
        this.enrollmentForm?.get('dipartimento')?.enable();
        this.enrollmentForm?.get('anno')?.enable();
      } else {
        this.enrollmentForm?.get('dipartimento')?.reset();
        this.enrollmentForm?.get('anno')?.reset();
      }
    });

    this.enrollmentForm
      ?.get('dipartimento')
      ?.valueChanges.subscribe((value: any) => {
        this.enrollmentForm?.get('anno')?.reset();
        if (value === 'Liceo Linguistico Internazionale') {
          this.enrollmentForm.get('seconda_lingua')?.setValue(null);
          this.enrollmentForm
            .get('seconda_lingua')
            ?.setValidators(Validators.required);
          this.enrollmentForm.get('terza_lingua')?.setValue('Spagnolo');
        } else {
          this.enrollmentForm.get('seconda_lingua')?.setValidators([]);
          if (
            [
              'Secondaria di I Grado',
              'Liceo delle Scienze Umane',
              'ITEM',
            ].includes(value)
          ) {
            this.enrollmentForm.get('terza_lingua')?.setValue(null);
            this.enrollmentForm.get('seconda_lingua')?.setValue('Spagnolo');
          } else {
            this.enrollmentForm.get('terza_lingua')?.setValue(null);
            this.enrollmentForm.get('seconda_lingua')?.setValue(null);
          }
        }

        if (this.enrollmentForm.get('dipartimento')?.value) {
          const academicYears =
            this._options
              .filter(
                (obj) => obj.value === this.enrollmentForm.get('sede')?.value
              )
              .at(0)
              ?.departmentsListing?.filter(
                (obj) =>
                  obj.title === this.enrollmentForm.get('dipartimento')?.value
              )
              .at(0)?.academicYearsListing || [];
          this.dynamicAcademicYearsListing = academicYears;
          this.enrollmentForm?.get('anno')?.enable();
        } else {
          this.enrollmentForm?.get('anno')?.reset();
        }
      });
  }

  validateStep(i: number, event: Event, alertsAllowed: boolean): boolean {
    let showDialog: boolean = false;

    const controlsAreValid = (
      fields: string[],
      control?: FormGroup<any> | AbstractControl<any, any>
    ) => {
      control ??= this.enrollmentForm;
      let results: (boolean | undefined)[] = [];
      for (let i = 0; i < fields.length; i++) {
        if (control.get(fields[i])) {
          control.get(fields[i])?.markAsDirty();
          results.push(control.get(fields[i])?.valid);
        } else console.error('Error: field', fields[i], 'not found!');
      }
      return results.filter((r) => r !== true).length === 0;
    };
    const getValidContacts = (
      users?: (FormGroup<any> | AbstractControl<any, any>)[]
    ): { emails: string[]; phones: string[] } => {
      users ??= [];
      let emails: string[] = [];
      let phones: string[] = [];
      users.forEach((user: any) => {
        const emailsArray = user.get('contatti.email') as FormArray;
        const phonesArray = user.get('contatti.telefono') as FormArray;
        for (let e = emailsArray.length - 1; e >= 0; e--) {
          const email = emailsArray.at(e)?.get('indirizzo')?.value;
          if (email) emails.push(email);
          else this.removeContactFields(user, 'email', e);
        }
        for (let t = phonesArray.length - 1; t >= 0; t--) {
          const phone = phonesArray.at(t)?.get('numero')?.value;
          if (phone) phones.push(phone);
          else this.removeContactFields(user, 'telefono', t);
        }
      });
      return { emails, phones };
    };

    let fields: string[] = [];
    switch (i) {
      case 0:
        fields = [
          'sede',
          'anno_scolastico',
          'dipartimento',
          'anno',
          'ultimo_anno.istituto',
          'ultimo_anno.anno_scolastico',
          'ultimo_anno.tipo',
          'ultimo_anno.dettaglio',
        ];
        if (
          this.enrollmentForm.get('dipartimento')?.value ===
          'Liceo Linguistico Internazionale'
        ) {
          fields.push('seconda_lingua');
        }
        showDialog = !controlsAreValid(fields);
        break;
      case 1:
        fields = [
          'cognome',
          'nome',
          'codice_fiscale',
          'sesso',
          'data_di_nascita',
          'luogo_di_nascita',
          'provincia_di_nascita',
          'nazionalita',
          'domicilio.indirizzo',
          'domicilio.civico',
          'domicilio.cap',
          'domicilio.citta',
          'domicilio.provincia',
          'residenza.indirizzo',
          'residenza.civico',
          'residenza.cap',
          'residenza.citta',
          'residenza.provincia',
        ];
        getValidContacts(this.enrollmentFormStudentUsers);
        showDialog = !controlsAreValid(
          fields,
          this.enrollmentFormStudentUsers.at(0)
        );
        break;
      case 2:
        const users = this.enrollmentFormNotStudentUsers;
        if (users.length === 0) {
          if (alertsAllowed) {
            this._confirmationService.confirm({
              target: event.target as EventTarget,
              message:
                'Per proseguire è necessario inserire almeno un genitore/tutore.',
              header: 'Attenzione',
              icon: 'pi pi-exclamation-triangle',
              acceptIcon: 'none',
              rejectIcon: 'none',
              acceptButtonStyleClass: 'p-button-primary p-button-outlined',
              rejectButtonStyleClass: 'p-button-primary p-button-link p-hide',
              acceptLabel: 'Ok, capito',
            });
          }
          return false;
        }
        fields = [
          'cognome',
          'nome',
          'codice_fiscale',
          'sesso',
          'data_di_nascita',
          'luogo_di_nascita',
          'provincia_di_nascita',
          'nazionalita',
          'domicilio.indirizzo',
          'domicilio.civico',
          'domicilio.cap',
          'domicilio.citta',
          'domicilio.provincia',
          'residenza.indirizzo',
          'residenza.civico',
          'residenza.cap',
          'residenza.citta',
          'residenza.provincia',
        ];
        for (let u = 0; u < users.length; u++) {
          const check = controlsAreValid(
            fields,
            this.enrollmentFormNotStudentUsers.at(u)
          );
          if (!showDialog) showDialog = !check;
        }
        break;
      case 3:
        fields = ['religione', 'dea_sport', 'regolamento'];
        showDialog = !controlsAreValid(fields);
        break;
      case 4:
        break;
    }

    if (showDialog && alertsAllowed) {
      this._confirmationService.confirm({
        target: event.target as EventTarget,
        message:
          'Per proseguire è necessario compilare tutti i campi obbligatori.<br/><span class="tw-font-light tw-text-xs">Promemoria: tutti i campi sono obbligatori di default ad esclusione di quelli contrassegnati come opzionali</span>',
        header: 'Attenzione',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptButtonStyleClass: 'p-button-primary p-button-outlined',
        rejectButtonStyleClass: 'p-button-primary p-button-link p-hide',
        acceptLabel: 'Ok, capito',
      });
    } else {
      if (i == 2) {
        const results = getValidContacts(this.enrollmentFormNotStudentUsers);
        const emails: string[] = results.emails;
        const phones: string[] = results.phones;

        const hasDuplicates = (array: string[]): boolean => {
          const uniqueValues = new Set(array);
          return uniqueValues.size !== array.length;
        };
        let message: string | undefined;
        if (emails.length === 0) {
          message =
            'Per proseguire è necessario inserire almeno 1 contatto email valido per genitori/tutori.';
        } else if (hasDuplicates(emails)) {
          message =
            'Sembra che un indirizzo email di un genitore/tutore sia stato inserito più volte.<br/><span class="tw-font-light tw-text-xs">Per proseguire è necessario sostituire i contatti email duplicati con dei valori validi.</span>';
        } else if (phones.length === 0) {
          message =
            'Per proseguire è necessario inserire almeno un contatto telefonico valido per genitori/tutori.';
        } else if (hasDuplicates(phones)) {
          message =
            'Sembra che un numero di telefono di un genitore/tutore sia stato inserito più volte.<br/><span class="tw-font-light tw-text-xs">Per proseguire è necessario sostituire i contatti telefonici duplicati con dei valori validi.</span>';
        }
        if (message) {
          showDialog = true;
          if (alertsAllowed) {
            this._confirmationService.confirm({
              target: event.target as EventTarget,
              header: 'Attenzione',
              message: message,
              icon: 'pi pi-exclamation-triangle',
              acceptIcon: 'none',
              rejectIcon: 'none',
              acceptButtonStyleClass: 'p-button-primary p-button-outlined',
              rejectButtonStyleClass: 'p-button-primary p-button-link p-hide',
              acceptLabel: 'Ok, capito',
            });
          }
        }
      }
    }
    return !showDialog;
  }
}
