<div class="tw-py-2">
  <!-- Step Progress Bar -->
  <div class="tw-flex tw-flex-row tw-justify-between tw-relative">
    <!-- Progress -->
    <div class="tw-absolute tw-z-0 tw-h-[5px] tw-w-full spb-base-bg" style="top: calc((100% - 5px) / 2);">
      <div class="tw-h-full tw-w-full tw-relative">
        <div class="tw-h-full tw-rounded-full transition-duration"
          style="background-color: var(--step-bar-primary-color);" [style.width.%]="percent">
        </div>
      </div>
    </div>
    <!-- Progress -->
    <!-- Steps -->
    <div *ngFor="let _ of steps; let step = index;"
      class="tw-h-8 tw-w-8 tw-rounded-full tw-z-10 tw-flex tw-justify-center tw-items-center spb-base-bg hover:tw-cursor-pointer transition-duration"
      [ngClass]="{'spb-primary-bg': index >= step}" (click)="setStep(step)">
      <div class="spb-step-pin-label transition-duration">{{(step+1)}}</div>
    </div>
    <!-- Steps -->
  </div>
  <!-- Step Progress Bar -->
  <!-- Labels -->
  <div class="tw-flex tw-flex-row tw-justify-between tw-mt-2">
    <div *ngFor="let step of steps; let index = index; let first = first; let last = last;" class="tw-w-8 tw-relative">
      <div
        class="tw-absolute tw-hidden sm:tw-block sm:tw-text-xs md:tw-text-base hover:tw-cursor-pointer tw-whitespace-nowrap transition-duration"
        [ngClass]="{'tw-opacity-0': index == (index+1)}" [style.left]="first ? '0%' : last ? '100%' : '50%'"
        [style.transform]="first ? 'translateX(-0%)' : last ? 'translateX(-100%)' : 'translateX(-50%)'"
        (click)="setStep((index+1))">
        {{step}}
      </div>
    </div>
  </div>
  <!-- Labels -->
</div>