export class ScholasticYear {
  private static splitter: number = 8;

  public static getCurrent(): string {
    const today = new Date();
    const year: number = today.getFullYear();
    const month: number = today.getMonth();
    const start: number = month < this.splitter ? year - 1 : year;
    const end: number = month < this.splitter ? year : year + 1;
    return `${start}-${end}`;
  }

  public static getFromCurrent(number: number): string {
    const years: string[] = this.getCurrent().split('-');
    return `${parseInt(years[0]) + number}-${parseInt(years[1]) + number}`;
  }

  public static getPrev(): string {
    return this.getFromCurrent(-1);
  }

  public static getNext(): string {
    return this.getFromCurrent(1);
  }
}
