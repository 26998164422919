import { DataService } from 'src/app/services/data.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-step-d',
  templateUrl: './step-d.component.html',
  styleUrls: ['./step-d.component.scss'],
})
export class StepDComponent {
  get dataService(): DataService {
    return this._dataService;
  }

  constructor(private _dataService: DataService) {}
}
