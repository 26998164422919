import { Component } from '@angular/core';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss'],
})
export class InfoPageComponent {
  public dialogTitle: string = '';
  public visible: boolean = false;
  public src: string = '';

  public info = {
    sede: 'Istituti E. De Amicis - Milano',
    luogo: 'Milano',
    descrizione: '',
    dipartimenti: [
      {
        nome: 'Secondaria di I Grado',
        link: 'https://static.deamicis.cloud/docs/rette-scolastiche/scuola-secondaria-di-I-grado.pdf',
      },
      {
        nome: 'Liceo delle Scienze Umane',
        link: 'https://static.deamicis.cloud/docs/rette-scolastiche/liceo-delle-scienze-umane-licos.pdf',
      },
      {
        nome: 'Liceo Scientifico',
        link: 'https://static.deamicis.cloud/docs/rette-scolastiche/liceo-scientifico.pdf',
      },
      {
        nome: 'Liceo Scientifico Sportivo',
        link: 'https://static.deamicis.cloud/docs/rette-scolastiche/liceo-scientifico-sportivo.pdf',
      },
      {
        nome: 'Liceo Linguistico Internazionale',
        link: 'https://static.deamicis.cloud/docs/rette-scolastiche/liceo-linguistico-internazionale.pdf',
      },
      {
        nome: 'Liceo Classico',
        link: 'https://static.deamicis.cloud/docs/rette-scolastiche/liceo-classico.pdf',
      },
      {
        nome: 'ITEM',
        link: 'https://static.deamicis.cloud/docs/rette-scolastiche/item.pdf',
      },
    ],
  };

  openPDF(nome: string, link: string): void {
    this.src = link;
    this.dialogTitle = `Rette Scolastiche: ${nome}`;
    this.visible = true;
  }
}
