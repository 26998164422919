import { RadioButtonInterface } from 'src/app/models/radio.interface';
import { ToolsService } from 'src/app/services/tools.service';
import { DataService } from 'src/app/services/data.service';
import { Component, inject, Input } from '@angular/core';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent {
  private _toolService: ToolsService = inject(ToolsService);
  private _dataService: DataService = inject(DataService);
  @Input() uuid!: string;

  get dataService(): DataService {
    return this._dataService;
  }

  get user() {
    return this._dataService.getUserByUUID(this.uuid);
  }

  get types(): RadioButtonInterface[] {
    return [
      { value: 'Parent', label: 'Genitore' },
      { value: 'Tutor', label: 'Tutore' },
    ];
  }

  get genders(): RadioButtonInterface[] {
    return [
      { value: 'M', label: 'Maschio' },
      { value: 'F', label: 'Femmina' },
    ];
  }

  get emailLabels(): RadioButtonInterface[] {
    return [
      { value: 'Personale', label: 'Personale' },
      { value: 'Lavoro', label: 'Lavoro' },
    ];
  }

  get phoneLabels(): RadioButtonInterface[] {
    return [
      { value: 'Cellulare', label: 'Cellulare' },
      { value: 'Lavoro', label: 'Lavoro' },
      { value: 'Casa', label: 'Casa' },
    ];
  }

  get phonePrefixesOptions(): { name: string; value: string }[] {
    return this._toolService.phonePrefixes
      .sort((p1, p2) => {
        if (p1.dialCode === '+39') return -1;
        if (p2.dialCode === '+39') return 1;
        return parseInt(p1.dialCode.slice(1)) - parseInt(p2.dialCode.slice(1));
      })
      .map((prefix) => {
        return {
          code: prefix.code,
          value: prefix.dialCode,
          name: `${prefix.flag} (${prefix.code}) ${prefix.dialCode}`,
        };
      });
  }
}
