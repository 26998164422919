import { DataService } from 'src/app/services/data.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-step-e',
  templateUrl: './step-e.component.html',
  styleUrls: ['./step-e.component.scss'],
})
export class StepEComponent {
  get dataService(): DataService {
    return this._dataService;
  }

  constructor(private _dataService: DataService) {}

  onLoaded(event: any): void {
    console.info('=> ', event);
  }
}
