import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { WebcamModule } from 'ngx-webcam';
// ICONS
import {
  LucideAngularModule,
  ChevronRight,
  ChevronLeft,
  Trash2,
} from 'lucide-angular';

// PRIME-NG
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AccordionModule } from 'primeng/accordion';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { StepProgressBarComponent } from './components/step-progress-bar/step-progress-bar.component';
import { StepWrapperComponent } from './components/dea-pack/step-wrapper.component';
import { StepBlockTitleComponent } from './components/dea-pack/step-block-title.component';
import { LabelComponent } from './components/dea-pack/label.component';
import { RadioButtonComponent } from './components/dea-pack/radio-button.component';
import { ContainerComponent } from './components/dea-pack/container.component';
import { StepBlockControllerComponent } from './components/dea-pack/step-block-controller.component';
import { FormGridComponent } from './components/dea-pack/form-grid.component';
import { InputUppercaseDirective } from './directives/input-uppercase.directive';
import { InputTitlecaseDirective } from './directives/input-titlecase.directive';
import { HrComponent } from './components/dea-pack/hr.component';
import { StepAComponent } from './components/steps/step-a/step-a.component';
import { StepBComponent } from './components/steps/step-b/step-b.component';
import { PhotoCaptureDialogComponent } from './components/photo-capture-dialog/photo-capture-dialog.component';
import { ImgUploadComponent } from './components/dea-pack/img-upload.component';
import { StepCComponent } from './components/steps/step-c/step-c.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { StepDComponent } from './components/steps/step-d/step-d.component';
import { StepEComponent } from './components/steps/step-e/step-e.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    StepProgressBarComponent,
    StepWrapperComponent,
    StepBlockTitleComponent,
    LabelComponent,
    RadioButtonComponent,
    ContainerComponent,
    StepBlockControllerComponent,
    StepAComponent,
    StepBComponent,
    FormGridComponent,
    InputUppercaseDirective,
    InputTitlecaseDirective,
    HrComponent,
    PhotoCaptureDialogComponent,
    ImgUploadComponent,
    StepCComponent,
    UserFormComponent,
    StepDComponent,
    StepEComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ButtonModule,
    TimelineModule,
    CardModule,
    CalendarModule,
    BadgeModule,
    RadioButtonModule,
    DropdownModule,
    InputTextModule,
    CheckboxModule,
    ToastModule,
    DialogModule,
    DynamicDialogModule,
    WebcamModule,
    InputSwitchModule,
    AccordionModule,
    LucideAngularModule.pick({ ChevronRight, ChevronLeft, Trash2 }),
  ],
  providers: [DialogService, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
