<footer class="tw-mt-8" style="background-color: var(--dea-grey); height: var(--dea-footer-height);">
  <app-container>
    <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-end tw-items-center">
      <div><strong>Istituti E. De Amicis</strong> srl</div>
      <div style="font-size: 0.75rem; opacity: 0.5;">De Amicis Ring - Enrollment Hash: {{hash}}</div>
      <div class="tw-mt-2 tw-mb-4" style="font-size: 0.5rem; opacity: 0.35;">
        <a class="hover:tw-underline" href="https://www.gabrielebellani.com" target="_blank">
          Made with love by G
        </a>
      </div>
    </div>
  </app-container>
</footer>