import { Component } from '@angular/core';

@Component({
  selector: 'app-step-block-title',
  template: `<div class="tw-text-2xl tw-font-semibold tw-mb-4">
    <ng-content></ng-content>
    <app-hr></app-hr>
  </div>`,
})
export class StepBlockTitleComponent {}
