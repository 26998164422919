export class EmailContact {
  label?: string;
  address?: string;
  enable?: boolean;

  constructor(json?: any) {
    if (!!!json) return;
    this.label = json['etichetta'];
    this.address = json['indirizzo'];
    this.enable =
      json['enable'] === undefined
        ? true
        : (json['enable'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
  }

  toJson(): any {
    if (!this.label && !this.address && this.enable === undefined) {
      return undefined;
    }
    return {
      etichetta: this.label,
      indirizzo: this.address,
      enable: this.enable,
    };
  }

  getEmailContactString(): string {
    return `${this.label} ${this.address}`.toString().trim();
  }
}
